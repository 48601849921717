import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import photoData from './photoData'; // Your image source data
import '../css/Gallery.css'; // Your updated CSS file
import '../css/backAboveButton.css';

const Gallery = () => {
    const { type } = useParams();

    // Use useMemo to memoize the photos array
    const photos = useMemo(() => {
        return photoData[type] || [];
    }, [type]);

    const [showButton, setShowButton] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('Beweeg over de afbeeldingen voor info en klik om projectdetails te bekijken!'); // Default message
    const [loadingImages, setLoadingImages] = useState([]); // To track loading state of images

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts

        // Check if the user has already seen the notification in this session
        const hasSeenNotification = sessionStorage.getItem('hasSeenGalleryNotification');
        if (!hasSeenNotification) {
            setShowNotification(true);
            // Hide the notification after 3 seconds
            setTimeout(() => {
                setShowNotification(false);
                sessionStorage.setItem('hasSeenGalleryNotification', 'true'); // Save the state in sessionStorage
            }, 3000);
        }

        // Update message based on screen size
        const updateMessage = () => {
            if (window.innerWidth <= 768) {
                setNotificationMessage('Swipe over de afbeeldingen voor meer info en tik om details te bekijken!'); // Message for small screens
            } else {
                setNotificationMessage('Beweeg over de afbeeldingen voor info en klik om projectdetails te bekijken!');
            }
        };

        // Initial check
        updateMessage();

        // Listen for resize events
        window.addEventListener('resize', updateMessage);

        const handleScroll = () => {
            if (window.scrollY > 20) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', updateMessage);
        };
    }, []);

    const navigateToProject = (project) => {
        navigate(`/gallery/${type}/${project}`);
    };

    // Function to handle touch events (show overlay on touch)
    const handleTouchStart = (index) => {
        const galleryItem = document.getElementById(`gallery-item-${index}`);
        if (galleryItem) {
            galleryItem.classList.add('touch-active'); // Add class to show overlay
        }
    };

    // Function to hide the overlay on touch end
    const handleTouchEnd = (index) => {
        const galleryItem = document.getElementById(`gallery-item-${index}`);
        if (galleryItem) {
            galleryItem.classList.remove('touch-active'); // Remove class to hide overlay
        }
    };

    // Handle image loading
    const handleImageLoad = (index) => {
        setLoadingImages((prevLoading) => prevLoading.filter(i => i !== index)); // Remove index from loading array
    };

    const handleImageError = (index) => {
        setLoadingImages((prevLoading) => prevLoading.filter(i => i !== index)); // Handle error case too
    };

    useEffect(() => {
        // Set all images as loading initially
        setLoadingImages(photos.map((_, index) => index));
    }, [photos]);

    return (
        <div className="containerRest">
            {/* Notification Overlay */}
            {showNotification && (
                <div className="notification-overlay">
                    <div className="notification-content">
                        <p>{notificationMessage}</p>
                    </div>
                </div>
            )}

            <div className="gallery">
                {photos.map((project, index) => (
                    <div
                        key={index}
                        id={`gallery-item-${index}`}
                        className="gallery-item"
                        onClick={() => navigateToProject(project.project)}
                        onTouchStart={() => handleTouchStart(index)} // Show overlay on touch
                        onTouchEnd={() => handleTouchEnd(index)} // Hide overlay when touch ends
                    >
                        {/* Show a loading spinner or placeholder while the image is loading */}
                        {loadingImages.includes(index) && (
                            <div className="image-placeholder">
                                <div className="spinner"></div> {/* A simple spinner */}
                            </div>
                        )}
                        <img
                            src={project.images[0].src}
                            alt={`${type} project`}
                            onLoad={() => handleImageLoad(index)}
                            onError={() => handleImageError(index)}
                            style={{ display: loadingImages.includes(index) ? 'none' : 'block' }} // Hide image if loading
                        />
                        <div className="overlay">
                            <div className="text">{project.description}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="button-container1">
                <button className="back-button" onClick={() => window.history.back()}>Terug</button>
            </div>
            {showButton && (
                <button id="backToTopBtn" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} title="Go to top">↑</button>
            )}
        </div>
    );
};

export default Gallery;
