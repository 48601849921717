import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import '../../css/Home.css';
import '../../css/backAboveButton.css';
import Slider from "../Slider";
import SEO from "../SEO";

function DynamicPage() {
    const bestelOptiesRef = useRef(null);
    const locationData = useLocation();
    const { location } = useParams(); // Get dynamic 'location' from the URL
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount

        const handleScroll = () => {
            if (window.scrollY > 20) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }

            const content = document.querySelector('.contentHome-container');
            if (content) {
                const contentPosition = content.getBoundingClientRect().top;
                const screenPosition = window.innerHeight / 1.3;

                if (contentPosition < screenPosition) {
                    content.classList.add('fade-in');
                    window.removeEventListener('scroll', handleScroll);
                }
            }
        };

        // Apply the scroll event listener on any path, including dynamic pages
        window.addEventListener('scroll', handleScroll);

        // Cleanup event listener on unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, [locationData]);

    const handleScrollToBestel = (e) => {
        e.preventDefault();
        const bestelOptiesSection = document.getElementById("bestel-opties");
        const bestelTitle = document.querySelector('.bestel-opties-wrapper'); // Select the title element
        if (bestelOptiesSection && bestelTitle) {
            let targetElement = bestelOptiesSection;
            let headerOffset = 100; // Default offset

            // Check for smaller screens and adjust the target element to the title
            if (window.innerWidth <= 768) {
                // For smaller screens, scroll to the title
                targetElement = bestelTitle;
                headerOffset = 50; // Adjust offset for smaller screens
            }

            const elementPosition = targetElement.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });

            // Add the 'visible' class after scrolling
            setTimeout(() => {
                bestelOptiesSection.classList.add("visible");
            }, 600); // Delay should match the duration of the scroll
        }
    };

    useEffect(() => {
        const targetElement = bestelOptiesRef.current;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("visible");
                    }
                });
            },
            {
                threshold: 0.1, // Trigger when 10% of the element is visible
            }
        );

        if (targetElement) {
            observer.observe(targetElement);
        }

        return () => {
            if (targetElement) {
                observer.unobserve(targetElement);
            }
        };
    }, []); // Empty dependency array ensures this only runs on mount and unmount

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const schemaMarkup = {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Ver-di Interieurbouw",
        "description": `Ver-Di interieurbouw zorgt voor maatwerk naar uw stijl. Wij doen voornamelijk keukens en badkamers maar ook voor maatwerk kan u ons inhuren. Actief in ${location} en omgeving.`,
        "url": `https://ver-di-interieurbouw.be/interieurbouw/${location}}`,
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Houten Schoen 122",
            "addressLocality": "Sint-Niklaas",
            "addressRegion": "Oost-Vlaanderen",
            "postalCode": "9100",
            "addressCountry": "BE"
        },
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+32 478 98 30 24",
            "contactType": "Customer Service",
            "areaServed": "BE",
            "availableLanguage": "Dutch"
        }
    };

    return (
        <>
            <SEO
                title={`Ver-di Interieurbouw | Maatwerk in ${location} | Interieurbouw en Schrijnwerkerij`}
                description={`Ver-Di interieurbouw zorgt voor maatwerk naar uw stijl in ${location}. Wij doen voornamelijk keukens en badkamers maar ook voor maatwerk kan u ons inhuren.`}
                keywords={`Ver-Di Interieurbouw, Maatwerk in ${location}, Interieur op maat, Keukens, Badkamers`}
                canonical={`https://ver-di-interieurbouw.be/interieurbouw/${location}`}
                schemaMarkup={schemaMarkup}
            />
            <div className="home-component-wrapper">
                <div className="home-component">
                    <div className="home-content-wrapper">
                        <div className="test slider-wrapper">
                            <Slider/>
                        </div>
                        <div className="home-content">
                            <div className="text-box">
                                <h1 className="title">Ver-Di</h1>
                                <p className="subtitle">Interieurprojecten in {location}</p>
                                <div className="buttons">
                                    <Link to="#bestel-opties" className="button donate" onClick={handleScrollToBestel}>
                                        Over Ons
                                    </Link>
                                    <Link to="/Contact" className="button event">
                                        Contacteer ons
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bestel-opties-wrapper">
                        <div className="bestel-opties" id="bestel-opties" ref={bestelOptiesRef}>
                            <div className="over-ons-container">
                                <div className="over-ons-text">
                                    <h2 className="bestelTitle">Over Ons</h2>
                                    <p>
                                        Ver-Di interieurbouw zorgt voor maatwerk naar uw stijl. Wij doen voornamelijk
                                        keukens en badkamers, maar ook voor maatwerk kan u onze hulp inhuren. Hieronder
                                        kan u een overzicht vinden van onze projecten. Actief in {location}.
                                    </p>
                                </div>
                                <div className="over-ons-image">
                                    <img src={require('../../img/overOns/overOns.jpg')} alt="Over Ons"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showButton && (
                <button id="backToTopBtn" onClick={scrollToTop} title="Go to top">↑</button>
            )}
        </>
    );
}

export default DynamicPage;
