import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import photoData from './photoData';
import '../css/ProjectGallery.css';
import '../css/backAboveButton.css';

const ProjectGallery = () => {
    const { type, project } = useParams();
    const [showButton, setShowButton] = useState(false);
    const [loadingImages, setLoadingImages] = useState([]); // To track loading state for images
    const selectedProject = photoData[type]?.find(proj => proj.project === project);
    const imageRefs = useRef([]); // Store refs for each image

    useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts

        const handleScroll = () => {
            setShowButton(window.scrollY > 20);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        // Initialize loading state to true for all images
        if (selectedProject) {
            setLoadingImages(new Array(selectedProject.images.length).fill(true));
        }
    }, [selectedProject]);

    // Detect if image is vertical or horizontal
    const handleImageLoad = (index) => {
        const imgElement = imageRefs.current[index];
        if (imgElement) {
            const aspectRatio = imgElement.naturalWidth / imgElement.naturalHeight;
            if (aspectRatio < 1) {
                // If the image is vertical (height > width), add the 'vertical' class
                imgElement.classList.add('vertical');
            }
            setLoadingImages(prevLoading => {
                const updatedLoading = [...prevLoading];
                updatedLoading[index] = false;
                return updatedLoading;
            });
        }
    };

    const handleImageError = (index) => {
        setLoadingImages(prevLoading => {
            const updatedLoading = [...prevLoading];
            updatedLoading[index] = false;
            return updatedLoading;
        });
    };

    if (!selectedProject) {
        return <div>Project not found</div>;
    }

    return (
        <div className="project-gallery-container">
            {/* Project Description */}
            <h3 className="project-description">{selectedProject.description}</h3>

            {/* Images displayed in a grid with 3 columns */}
            <div className="project-gallery">
                {selectedProject.images.map((image, index) => (
                    <div key={index} className="project-gallery-item">
                        {/* Show a loading spinner or placeholder while the image is loading */}
                        {loadingImages[index] && (
                            <div className="image-placeholder">
                                <div className="spinner"></div>
                            </div>
                        )}
                        <img
                            ref={el => (imageRefs.current[index] = el)} // Store a reference to each image
                            src={image.src}
                            alt={`${project} ${index + 1}`}
                            onLoad={() => handleImageLoad(index)} // Detect if image is vertical
                            onError={() => handleImageError(index)} // Handle errors too
                            style={{ display: loadingImages[index] ? 'none' : 'block' }} // Hide image if loading
                        />
                        <h3>{image.description}</h3>
                    </div>
                ))}
            </div>

            {/* Back Button */}
            <button className="back-button" onClick={() => window.history.back()}>Terug</button>
            {showButton && (
                <button id="backToTopBtn" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} title="Go to top">↑</button>
            )}
        </div>
    );
};

export default ProjectGallery;
