import React from 'react';
import { Helmet } from 'react-helmet-async'; // Use HelmetProvider

const SEO = ({ title, description, keywords, canonical, schemaMarkup }) => {
    return (
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
                <meta name="keywords" content={keywords}/>
                <link rel="canonical" href={canonical}/>
                <meta property="og:url" content={canonical}/>
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={description}/>
                <meta property="og:type" content="website"/>
                <script type="application/ld+json">
                    {JSON.stringify(schemaMarkup)}
                </script>
            </Helmet>
    );
};

export default SEO;
